import { Interceptors } from '@/utils/interceptor';

const request = new Interceptors().getInterceptors();

/**
 * POST 方法 与 GET方法  区别
 *
 * post==> data: obj
 * get==>params: obj:any
 *
 */
export const Api = {
  // 获取菜单
 /* login() {
    return request({
      //url: '/data1/login.php',
	  url: 'https://chaojibaoan2.ec666.net/api/user/login/',
	  data: {
	  	username:"kouling_1234",
		"pwd": ""
	  },
      method: 'POST'
    });
  }, */
  // 获取菜单
  getdata(fangquid) {
    return request({
      url: '/data1/getdata.php?fangquid=' + fangquid,
      method: 'get'
    });
  },
  getdata4(contentid) {
    return request({
      url: '/data1/getdata4.php?contentid=' + contentid,
      method: 'get'
    });
  },
  getdata3(contentid) {
    return request({
      url: '/data1/getdata3.php?contentid=' + contentid,
      method: 'get'
    });
  },
  getdata2(contentid) {
    return request({
      url: '/data1/getdata2.php?contentid=' + contentid,
      method: 'get'
    });
  },
  getdata1(contentid) {
    return request({
      url: '/data1/getdata1.php?contentid=' + contentid,
      method: 'get'
    });
  },
  gettianqi(fangquid,city,daCode) {
    return request({
      url: '/data1/tianqi/gettianqi.php?fangquid=' + fangquid+'&city='+city+'&daCode='+daCode,
      method: 'get'
    });
  },
  getduiyuan1(fangquid) {
    return request({
      url: '/data1/duiyuan/getduiyuan1.php?fangquid=' + fangquid,
      method: 'get'
    });
  },
  getduiyuan2(fangquid) {
    return request({
      url: '/data1/duiyuan/getduiyuan2.php?fangquid=' + fangquid,
      method: 'get'
    });
  },
  getduiyuan3(fangquid) {
    return request({
      url: '/data1/duiyuan/getduiyuan3.php?fangquid=' + fangquid,
      method: 'get'
    });
  },
  getzhiqinjiluyi(fangquid) {
    return request({
      url: '/data1/getzhiqinjiluyi.php?fangquid=' + fangquid,
      method: 'get'
    });
  },
  getcar(fangquid) {
    return request({
      url: '/data1/getcar.php?fangquid=' + fangquid,
      method: 'get'
    });
  },
  getrenwu(fangquid) {
    return request({
      url: '/data1/getrenwu.php?fangquid=' + fangquid,
      method: 'get'
    });
  },
  getgaojing(fangquid) {
    return request({
      url: '/data1/getgaojing.php?fangquid=' + fangquid,
      method: 'get'
    });
  },
  getrenyuan(fangquid) {
    return request({
      url: '/data1/getrenyuan.php?fangquid=' + fangquid,
      method: 'get'
    });
  },

  getcarhuichuan(fangquid) {
    return request({
      url: '/data1/getcarhuichuan.php?fangquid=' + fangquid,
      method: 'get'
    });
  },

  getfangquinfo(fangquid) {
    return request({
      url: '/data1/getfangquinfo.php?fangquid=' + fangquid,
      method: 'get'
    });
  },

  getcarinfo(cheliangid) {
    return request({
      url: '/data1/getcarinfo.php?cheliangid=' + cheliangid,
      method: 'get'
    });
  },

  getjiankongtv(cheliangid) {
    return request({
      url: '/data1/getjiankongtv.php?cheliangid=' + cheliangid,
      method: 'get'
    });
  },

  getjiashicang(cheliangid) {
    return request({
      url: '/data1/getjiashicang.php?cheliangid=' + cheliangid,
      method: 'get'
    });
  },

  getduiyuanxiangqing(duiyuanid) {
    return request({
      url: '/data1/getduiyuanxiangqing.php?duiyuanid=' + duiyuanid,
      method: 'get'
    });
  },

  getgaojingxinxi(token,robotCode) {
    return request({
      url: '/data1/getgaojingxinxi.php?robotCode=' + robotCode+'&token='+token,
      method: 'get'
    });
  },
  
  kaishiboyin(token,alarmType,robotCode){
	  return request({
	    url: '/data1/kaishiboyin.php?alarmType='+alarmType+'&robotCode=' + robotCode+'&token='+token,
	    method: 'get'
	  });
  },

  getduiyuanguiji(id) {
    return request({
      url: '/data1/getduiyuanguiji2.php?user_id=' + id,
      method: 'get'
    });
  },

  getcheliangguiji(id) {
    return request({
      url: '/data1/getcheliangguiji.php?id=' + id,
      method: 'get'
    });
  },
  getbaoanlist(data) {
    return request({
      url: '/api/user/eventUser?adminKey=longzongyyds',
      method: 'post'
    });
  },
  getwurenchelist(token) {
    return request({
      url: '/data1/wurenchelist.php?token=' + token,
	  data: {
	  	token:token,
	  },
      method: "get",
    });
  },
  getfangqulist(token) {
    return request({
      url: '/data1/getfangqulist.php?token=' + token,
  	  data: {
  	  	token:token,
  	  },
      method: "get",
    });
  },
  baocunzuobiao(deviceid,lat,lng) {
    return request({
      url: '/data1/baocunzuobiao.php?deviceid=' + deviceid+'&lat='+lat+'&lng='+lng,
      method: "get",
    });
  },
  qianwangxunjian(point,robotCode,mapName,token){
	  return request({
	    url: '/data1/qianwangxunjian.php?point=' + point+'&robotCode='+robotCode+'&mapName='+mapName+'&token='+token,
	    method: "get",
	  });
  },
  cheliangzhuangtai(robotCode,token){
	  return request({
	    url: '/data1/cheliangzhuangtai.php?robotCode='+robotCode+'&token='+token,
	    method: "get",
	  });
  },
  diaodujilu(renwubianhao,xunjiandianid,robotCode,robotName,starttime,endtime,suoshuxiaoqu){
  	  return request({
  	    url: '/data1/diaodujilu.php?renwubianhao='+renwubianhao+'&xunjiandianid='+xunjiandianid+'&robotCode='+robotCode+'&robotName='+robotName+'&starttime='+starttime+'&endtime='+endtime+'&suoshuxiaoqu='+suoshuxiaoqu,
  	    method: "get",
  	  });
  },
  //一键报警日志

  yijianbaojinglog(lat,lng,daCode,deviceId,productId,voiceDeviceId,robotName,robotCode,suoshuxiaoqu){
	  return request({
	    url: '/data1/yijianbaojinglog.php?lat='+lat+'&lng='+lng+'&dacode='+dacode+'&robotname='+robotName+'&deviceid='+deviceId+'&productid='+productId+'&voicedeviceid='+voiceDeviceId+'&robotcode='+robotCode+'&suoshuxiaoqu='+suoshuxiaoqu,
	    method: "get",
	  });
  },
  
  //指挥中心呼叫无人车日志
  
  hujiaolog(daCode,deviceId,productId,voiceDeviceId,robotName,robotCode,suoshuxiaoqu){
  	  return request({
  	    url: '/data1/hujiaolog.php?dacode='+dacode+'&robotname='+robotName+'&deviceid='+deviceId+'&productid='+productId+'&voicedeviceid='+voiceDeviceId+'&robotcode='+robotCode+'&suoshuxiaoqu='+suoshuxiaoqu,
  	    method: "get",
  	  });
  },
  
  //车身报警日志
  cheshenbaojinglog(lat,lng,dacode,deviceid,robotname,robotcode,sensors1text,sensors2text,sensors3text,motortext,suoshuxiaoqu){
	  return request({
	    url: '/data1/cheshenbaojinglog.php?lat='+lat+'&lng='+lng+'&dacode='+dacode+'&robotname='+robotname+'&deviceid='+deviceid+'&robotcode='+robotcode+'&sensors1text='+sensors1text+'&sensors2text='+sensors2text+'&sensors3text='+sensors3text+'&motortext='+motortext+'&suoshuxiaoqu='+suoshuxiaoqu,
	    method: "get",
	  });
  },
  
  //==监听是否有保安呼叫指挥中心==
  isyouhujiaozhihuizhongixin(fangquid){
	  return request({
	    url: '/data1/isyouhujiaozhihuizhongixin.php?fangquid='+fangquid,
	    method: "get",
	  });
  },
  //==监听是否有保安呼叫指挥中心 end==
  
  //==获取防区LOGO==
  getfangqulogo(fangquidstr){
  	  return request({
  	    url: '/data1/getfangqulogo.php?fangquidstr='+fangquidstr,
  	    method: "get",
  	  });
  },
  //==LOGO end==
  
  
};
